import { Box, Image, Link, VStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "adminComponents/atoms/Button";
import { Divider } from "adminComponents/atoms/Divider";
import { Heading } from "adminComponents/atoms/Heading";
import { Logo } from "adminComponents/atoms/Logo";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";
import i18n from "lib/i18n";
import { AgreementType, IAgreement, UserRole } from "links/lib/types";
import { getBrandConditionedAgreementUrl } from "links/lib/util";

import roleArt from "./resource/livvy-image.png";

interface IProps {
  isOpen: boolean;
  isTeacherIntent?: boolean;
  handleClose: () => void;
  handleChooseRole: (role: UserRole) => void;
  agreements: Array<IAgreement>;
}

export const RolePickerModal: React.FC<IProps> = ({
  isOpen,
  isTeacherIntent,
  handleClose,
  handleChooseRole,
  agreements,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "rolePickerModal",
  });

  const agreementsMinusCOPPA = useMemo(() => {
    return agreements.filter(
      (agreement) => agreement.agreement_type !== AgreementType.COPPA
    );
  }, [agreements]);

  const agreementsListMinusCOPPA = useMemo(() => {
    return new Intl.ListFormat(i18n.language, {
      style: "long",
      type: "conjunction",
    }).formatToParts(agreementsMinusCOPPA.map((a) => a.id));
  }, [agreementsMinusCOPPA]);

  const coppaAgreement = useMemo(() => {
    return agreements.find(
      (agreement) => agreement.agreement_type === AgreementType.COPPA
    );
  }, [agreements]);

  const buttons = isTeacherIntent ? (
    <>
      <Button
        variant="adminButtonFilled"
        w="full"
        onClick={() => handleChooseRole(UserRole.Teacher)}
      >
        {t("buttonLabelTeacher")}
      </Button>
      <Divider color="primary.tan" />
      <Button
        variant="adminButtonOutlined"
        w="full"
        onClick={() => handleChooseRole(UserRole.Student)}
      >
        {t("buttonLabelStudent")}
      </Button>
    </>
  ) : (
    <>
      <Button
        variant="adminButtonFilled"
        w="full"
        onClick={() => handleChooseRole(UserRole.Student)}
      >
        {t("buttonLabelStudent")}
      </Button>
      <Divider color="primary.tan" />
      <Button
        variant="adminButtonOutlined"
        w="full"
        onClick={() => handleChooseRole(UserRole.Teacher)}
      >
        {t("buttonLabelTeacher")}
      </Button>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      showBackButton={false}
      variant="adminStudentsModal"
      headingProps={{
        as: "h2",
        fontSize: [pxToRem(18), null, pxToRem(24)],
        variant: "adminH6",
      }}
      hideClose
      size="2xl"
    >
      <VStack p={pxToRem(20)} paddingTop={pxToRem(50)} spacing={pxToRem(24)}>
        <Box width={pxToRem(250)}>
          <Logo />
        </Box>
        <Image src={roleArt} alt={t("alt")} />
        <Heading as="h3" variant="adminH3">
          {isTeacherIntent ? t("titleIntentTeacher") : t("title")}
        </Heading>
        {!isTeacherIntent && (
          <Text color="primary.dark-gray">{t("subtitle")}</Text>
        )}
        {buttons}
        <Text color="primary.dark-gray">
          {t("agreementsCopyInitial")}
          &nbsp;
          {agreementsListMinusCOPPA.length > 0 && (
            <>
              {t("agreementsCopy")}
              &nbsp;
              {agreementsListMinusCOPPA.map((item) => {
                if (item.type === "literal") return item.value;
                const agreement = agreements.find((a) => a.id === item.value);
                if (!agreement) return;

                return (
                  <Link
                    key={agreement.id}
                    color="utility.link"
                    href={getBrandConditionedAgreementUrl(
                      agreement.agreement_url
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {agreement.title}
                  </Link>
                );
              })}
            </>
          )}
          {!coppaAgreement && <>.</>}
          {agreementsListMinusCOPPA.length > 0 && !!coppaAgreement && (
            <>;&nbsp;{t("and")}&nbsp;</>
          )}
          {coppaAgreement && (
            <>
              {t("agreementsCopyCOPPA")}
              &nbsp;
              <Link
                key={coppaAgreement.id}
                color="utility.link"
                href={getBrandConditionedAgreementUrl(
                  coppaAgreement.agreement_url
                )}
                target="_blank"
                rel="noreferrer"
              >
                {coppaAgreement.title}
              </Link>
              .
            </>
          )}
        </Text>
      </VStack>
    </Modal>
  );
};
