import { SignInIntent } from "@goguardian/types-psi";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { config, localStoreAuthKeyName } from "../constants";
import { useUpdateUserRole } from "../features/users";
import useFetchCurrentUser from "../features/users/useFetchCurrentUser";
import useInitializeUserRole from "../features/users/useInitializeUserRole";
import { useAxios } from "../hooks/useAxios";
import { useLocalStore } from "../hooks/useLocalStore";
import {
  IAgreement,
  IFeatureFlags,
  ILicense,
  ISharingSettings,
  IUser,
  UserRole,
} from "../types";

export interface IAuthState {
  authRequiredAgreements?: Array<IAgreement>;
  authFeatureFlags?: IFeatureFlags;
  authSharingSettings?: ISharingSettings;
  authUser?: IUser;
  authToken?: string;
  authLicense?: ILicense;
  hasNoPremiumAccess: boolean;
  isGhostUser: boolean;
  isAuthLoading: boolean;
}

export interface IExternalSignInState {
  externalSignInIntent?: SignInIntent;
  externalSignInRedirectTo?: string;
}

export interface IAuthContext extends IAuthState, IExternalSignInState {
  isFeatureEnabled(flag: string): boolean;
  getFeatureFlags: () => IFeatureFlags | undefined;
  setAuth: (state: IAuthState) => void;
  setAuthToken: (token: string) => void;
  setExternalSignInState: (state: IExternalSignInState) => void;
  refetchUser: () => void;
  initializeUserRole: (
    isStudent: boolean,
    accepted_agreement_ids?: Array<string>
  ) => void;
  updateUserRole: (user_id: string, role: UserRole) => void;
}

export const emptyAuthContext = {
  setAuth: (): void => {
    // do nothing
  },
  setAuthToken: (): void => {
    // do nothing
  },
  setExternalSignInState: (): void => {
    // do nothing
  },
  refetchUser: (): void => {
    // do nothing
  },
  initializeUserRole: (): void => {
    // do nothing
  },
  updateUserRole: (): void => {
    // do nothing
  },
  isFeatureEnabled: (): boolean => {
    return false;
  },
  getFeatureFlags: (): IFeatureFlags | undefined => {
    return undefined;
  },
  hasNoPremiumAccess: false, // Defaults to open
  isGhostUser: false,
  isAuthLoading: true,
};

export const AuthContext = createContext<IAuthContext>(emptyAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<IAuthState>({
    hasNoPremiumAccess: false,
    isGhostUser: false,
    isAuthLoading: true,
  });
  const [externalSignInState, setExternalSignInState] =
    useState<IExternalSignInState>({});
  const localStore = useLocalStore();
  const axios = useAxios();
  const history = useHistory();

  const {
    data: currentUserData,
    execute: fetchCurrentUser,
    isLoading: isFetchCurrentUserLoading,
  } = useFetchCurrentUser();

  const {
    execute: initializeUserRole,
    isLoading: isInitializeUserRoleLoading,
  } = useInitializeUserRole();

  const { execute: updateUserRole, isLoading: isUpdateUserRoleLoading } =
    useUpdateUserRole();

  useEffect(() => {
    if (currentUserData?.user) {
      setState((state) => ({
        ...state,
        authUser: currentUserData.user,
        authRequiredAgreements: currentUserData.required_agreements,
        authFeatureFlags: currentUserData.features,
        authSharingSettings: currentUserData.sharing_settings,
        authLicense: currentUserData.license,
        hasNoPremiumAccess: !currentUserData.license.has_premium_access,
        isGhostUser: currentUserData.user.ghost_user_email != "",
        isAuthLoading: false,
      }));
    }
  }, [currentUserData]);

  const value = useMemo(
    () => ({
      ...state,
      ...externalSignInState,
      isAuthLoading:
        isFetchCurrentUserLoading ||
        isInitializeUserRoleLoading ||
        isUpdateUserRoleLoading,
      setAuth: (state: IAuthState) => setState(state),
      setAuthToken: (token: string) => {
        localStore.setItem(localStoreAuthKeyName, token);

        // update axios client to use current JWT
        // for authorization header on all requests
        axios.defaults.headers.common["Authorization"] = token;
        setState({ ...state, authToken: token });
      },
      setExternalSignInState: (newState: IExternalSignInState) => {
        let redirectTo = newState.externalSignInRedirectTo;
        if (redirectTo) {
          const redirectToOrigin = new URL(redirectTo).origin;
          if (
            !config.allowedExternalSignInRedirectOrigins.has(redirectToOrigin)
          ) {
            console.warn(
              "Ignoring redirectTo for disallowed origin",
              redirectToOrigin
            );
            redirectTo = undefined;
          }
        }

        setExternalSignInState({
          externalSignInIntent: newState.externalSignInIntent,
          externalSignInRedirectTo: redirectTo,
        });
      },
      refetchUser: () => fetchCurrentUser(),
      isFeatureEnabled: (flag: string) => {
        return state.authFeatureFlags?.[flag] ?? false;
      },
      getFeatureFlags: (): IFeatureFlags | undefined => {
        return state.authFeatureFlags;
      },
      initializeUserRole: (
        isStudent: boolean,
        accepted_agreement_ids?: Array<string>
      ) => {
        initializeUserRole({
          isStudent,
          accepted_agreement_ids,
        }).then((res) => {
          const token = res?.token;

          if (token) {
            localStorage.setItem(localStoreAuthKeyName, token);

            // update axios client to use current JWT
            // for authorization header on all requests
            axios.defaults.headers.common["Authorization"] = token;
          }

          fetchCurrentUser();
        });
      },
      updateUserRole: (user_id: string, role: UserRole) => {
        updateUserRole({
          user_id,
          role,
        }).then((res) => {
          const token = res?.token;

          if (token) {
            localStorage.setItem(localStoreAuthKeyName, token);

            // update axios client to use current JWT
            // for authorization header on all requests
            axios.defaults.headers.common["Authorization"] = token;
            setState((state) => ({ ...state, authToken: token }));
          }

          // reload to use new role
          fetchCurrentUser().then(() => history.push("/"));
        });
      },
    }),
    [
      state,
      externalSignInState,
      fetchCurrentUser,
      localStore,
      initializeUserRole,
      updateUserRole,
      axios.defaults.headers.common,
      isFetchCurrentUserLoading,
      isInitializeUserRoleLoading,
      isUpdateUserRoleLoading,
      history,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
