import React from "react";
import { Redirect } from "react-router-dom";

import { useAuth } from "links/lib/features/auth";
import { UserRole } from "links/lib/types";
import { useShowNewLibrary } from "sharedComponents/hooks/useShowNewLibrary";

/**
 * Redirects to the student home
 * or teacher home based on authenticated
 * user role.
 * @returns HomeRedirect
 */
const HomeRedirect: React.FC = () => {
  const { authUser: user, externalSignInRedirectTo } = useAuth();
  const newLibraryFeatureEnabled = useShowNewLibrary();

  if (!user)
    throw new Error("Attempting to redirect to home but not authenticated");

  if (user.role !== UserRole.Uninitialized && externalSignInRedirectTo) {
    window.location.href = externalSignInRedirectTo;
    return null;
  }

  let redirectPath = "";
  switch (user.role) {
    case UserRole.Student:
      redirectPath = user.is_guest ? "/sign-in/student" : "/s/home";
      break;
    case UserRole.Admin:
    case UserRole.Teacher:
    case UserRole.ContentSpecialist:
      if (newLibraryFeatureEnabled) {
        redirectPath = "/t/explore";
      } else {
        redirectPath = user.is_guest ? "/sign-in/teacher" : "/t/home";
      }
      break;
    case UserRole.Uninitialized:
      redirectPath = "/u/home";
      break;
  }

  return <Redirect to={redirectPath} />;
};

export default HomeRedirect;
