import {
  Box,
  Flex,
  FlexOptions,
  HStack,
  Show,
  Spinner,
  Stack,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Card } from "adminComponents/atoms/Card";
import { Icon } from "adminComponents/atoms/Icon";
import { PremiumIcon } from "adminComponents/atoms/PremiumIcon";
import { PremiumTooltipRich } from "adminComponents/atoms/PremiumTooltipRich";
import { Tag } from "adminComponents/atoms/Tag";
import { Text } from "adminComponents/atoms/Text";
import { pxToRem } from "adminComponents/utils";

interface IPracticeSetTypeCardProps {
  isActive?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isPremium?: boolean;
  isNew?: boolean;
  hasNoPremiumAccess?: boolean;
  disabledTooltip?: string;
  disabledLabel?: string;
  heading: string;
  subheading: string;
  icon: React.ReactNode | ((isHover: boolean) => React.ReactNode);
  iconAlignment?: FlexOptions["align"];
  onClick: () => void;
}

export const PracticeSetTypeCard: React.FC<IPracticeSetTypeCardProps> = ({
  isActive,
  isDisabled: _isDisabled,
  isLoading,
  isPremium,
  isNew,
  hasNoPremiumAccess,
  disabledTooltip,
  disabledLabel,
  heading,
  subheading,
  icon,
  iconAlignment,
  onClick,
}) => {
  const premiumActionsDisallowed = isPremium && hasNoPremiumAccess;
  const isDisabled = _isDisabled || premiumActionsDisallowed;

  const [isHover, setIsHover] = useState<boolean>(false);
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  return (
    <Card
      as="button"
      variant="adminCardSmallBorder"
      borderColor={
        isActive && !(isLoading || isDisabled)
          ? "primary.golden"
          : "primary.tan"
      }
      _hover={{
        color: isDisabled || isLoading ? undefined : "primary.golden",
      }}
      onClick={() => {
        if (isDisabled || isLoading) return;
        return onClick();
      }}
      cursor={isDisabled || isLoading ? "not-allowed" : "pointer"}
      bg={isDisabled && !isLoading ? "primary.warm-white" : "white"}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      position="relative"
    >
      {isLoading && (
        <Flex
          w="full"
          h="full"
          position="absolute"
          top="0"
          left="0"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="xl" color="primary.dark-tan" />
        </Flex>
      )}

      <Stack
        spacing={pxToRem(24)}
        justifyContent={["center", "flex-start"]}
        direction={["column", "row"]}
        opacity={isLoading ? 0.5 : 1}
      >
        <Flex flex="0" justifyContent="center" alignItems={iconAlignment}>
          {typeof icon === "function"
            ? icon(!isLoading && !isDisabled && isHover)
            : icon}
        </Flex>
        <VStack flex="1" alignItems={["center", "flex-start"]}>
          <HStack>
            <Text variant="adminP2Bold">{heading}</Text>
            {isNew && (
              <Show above="sm">
                <Tag variant="adminSolid" colorScheme="orange" h="auto">
                  {t("common.new")}
                </Tag>
              </Show>
            )}
            {isPremium && !premiumActionsDisallowed && <PremiumIcon />}
            {premiumActionsDisallowed && (
              <PremiumTooltipRich
                isDisabled={!premiumActionsDisallowed}
                placement="bottom"
              >
                <Box>
                  <Icon icon="lock" />
                </Box>
              </PremiumTooltipRich>
            )}
          </HStack>
          <Text variant="adminP2" textAlign={["center", "left"]}>
            {subheading}
          </Text>
          {!isLoading && isDisabled && disabledLabel && !hasNoPremiumAccess && (
            <Tag
              tooltip={disabledTooltip}
              variant="adminOutline"
              colorScheme="tan"
            >
              {disabledLabel}
            </Tag>
          )}
        </VStack>
      </Stack>
    </Card>
  );
};
