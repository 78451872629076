import { Box, Hide, Image, Link, SimpleGrid, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { usePrevious } from "react-use";

import { Button } from "adminComponents/atoms/Button";
import {
  CleverSignInButton,
  GoogleSignInButton,
  MicrosoftSignInButton,
} from "adminComponents/atoms/ExternalAccountButton";
import { Heading } from "adminComponents/atoms/Heading";
import { LoadingSpinner } from "adminComponents/atoms/LoadingSpinner";
import { Modal } from "adminComponents/atoms/Modal";
import { Text } from "adminComponents/atoms/Text";
import { SignInProps } from "adminComponents/screens/SignInScreen";
import { pxToRem } from "adminComponents/utils";
import { productName } from "links/lib/constants";

import curveImage from "./resources/curve.svg";
import studentsImage from "./resources/students.png";
import SuccessfulSignUpGif from "./resources/successful-sign-up.gif";

type TeacherSignInModalProps = SignInProps & {
  coppaUrl: string;
  isOpen: boolean;
  isLoading: boolean;
  hideImage?: boolean;
  title?: string;
  description?: string;
  isSuccessfulSignUp?: boolean;
  handleNotTeacherClick?: () => void;
  handleClose: () => void;
};

export const TeacherSignInModal: React.FC<TeacherSignInModalProps> = ({
  handleSignInWithCleverButtonClick,
  handleSignInWithGoogleButtonClick,
  handleSignInWithMicrosoftButtonClick,
  handleNotTeacherClick,
  handleClose,
  isLoading,
  coppaUrl,
  isOpen,
  hideImage = false,
  title,
  description,
  isSuccessfulSignUp,
}) => {
  const { t } = useTranslation("admin", {
    useSuspense: false,
  });

  const [showSuccessfulSignUpLoading, setShowSuccessfulSignUpLoading] =
    useState(false);
  const prevIsSuccessfulSignUp = usePrevious(isSuccessfulSignUp);
  useEffect(() => {
    if (
      prevIsSuccessfulSignUp !== undefined &&
      prevIsSuccessfulSignUp !== isSuccessfulSignUp
    ) {
      setShowSuccessfulSignUpLoading(true);
      setTimeout(() => {
        setShowSuccessfulSignUpLoading(false);
      }, 2000);
    }
  }, [isSuccessfulSignUp, prevIsSuccessfulSignUp]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      showBackButton={false}
      modalContentProps={{
        borderWidth: pxToRem(2),
        borderColor: "primary.tan",
      }}
      modalBodyProps={{
        padding: hideImage ? pxToRem(32) : "0 !important",
      }}
      isCentered
    >
      <SimpleGrid
        columns={{ base: 1, md: hideImage ? 1 : 2 }}
        paddingBottom={0}
        borderTopLeftRadius={pxToRem(24)}
      >
        {!isSuccessfulSignUp && !hideImage && (
          <Hide below="md">
            <VStack
              bgColor="primary.golden"
              borderTopLeftRadius={pxToRem(18)}
              borderBottomLeftRadius={pxToRem(18)}
            >
              <VStack
                bgImage={studentsImage}
                h={pxToRem(360)}
                w="full"
                bgPos="center center"
                bgSize="cover"
                borderTopLeftRadius={pxToRem(18)}
                justifyContent="flex-end"
              >
                <Image src={curveImage} w="full" />
              </VStack>
              <VStack
                p={pxToRem(48)}
                pt={0}
                spacing={pxToRem(18)}
                alignItems={"flex-start"}
                mt={`-${pxToRem(8)} !important`}
              >
                <Heading as="h4" variant="adminH5Gooper">
                  {productName}
                </Heading>

                <Heading as="p" variant="adminH5Bold">
                  <Text
                    as="span"
                    fontFamily={`"Gooper", Georgia, serif`}
                    fontSize={pxToRem(28)}
                    fontWeight={500}
                  >
                    {t("teacherSignInModal.labelSaveTime")}
                  </Text>{" "}
                  {t("teacherSignInModal.labelSaveTimeContinued")}
                </Heading>
              </VStack>
            </VStack>
          </Hide>
        )}
        {!isSuccessfulSignUp && (
          <VStack
            spacing={pxToRem(12)}
            w="full"
            p={pxToRem(24)}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            <Heading as="h3" variant="adminH4">
              {title || t("teacherSignInModal.heading")}
            </Heading>
            <Text size="xs" pb={pxToRem(12)}>
              {description || t("teacherSignInModal.description")}
            </Text>
            <VStack spacing={pxToRem(12)} w="full" alignItems={"flex-start"}>
              <Box id="psi_sign_in" data-auto-init="true" />
              <GoogleSignInButton
                handleOnButtonClick={handleSignInWithGoogleButtonClick}
                isLoading={isLoading}
              />
              <CleverSignInButton
                handleOnButtonClick={handleSignInWithCleverButtonClick}
                isLoading={isLoading}
              />
              <MicrosoftSignInButton
                handleOnButtonClick={handleSignInWithMicrosoftButtonClick}
                isLoading={isLoading}
              />
              {handleNotTeacherClick && (
                <Button
                  size="xs"
                  sx={{
                    fontSize: pxToRem(14),
                  }}
                  onClick={handleNotTeacherClick}
                  variant="adminTextButtonLarge"
                >
                  {t("teacherSignInModal.buttonNotATeacher")}
                </Button>
              )}
            </VStack>
            <Text pt={pxToRem(32)} variant="adminMeta">
              <Trans i18nKey="teacherSignInScreen.coppaDisclosure" t={t}>
                <Link target="_blank" color="utility.link" href={coppaUrl}>
                  COPPA Disclosure
                </Link>
              </Trans>
            </Text>
          </VStack>
        )}
        {isSuccessfulSignUp &&
          (showSuccessfulSignUpLoading ? (
            <VStack alignItems="center">
              <Heading as="h3" variant="adminH4">
                {t("teacherSignInModal.headingSuccessfulSignIn")}
              </Heading>
              <Box py={pxToRem(100)}>
                <LoadingSpinner />
              </Box>
            </VStack>
          ) : (
            <VStack w="full">
              <Image boxSize={pxToRem(300)} src={SuccessfulSignUpGif} />
            </VStack>
          ))}
      </SimpleGrid>
    </Modal>
  );
};
