import { VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { PracticeSetTypeCard } from "adminComponents/molecules/PracticeSetTypeCard";
import { pxToRem } from "adminComponents/utils";
import { useAuth } from "links/lib/features/auth";

import { ReactComponent as CustomSVG } from "./resource/add_circle.svg";
import { ReactComponent as RemixSVG } from "./resource/remix.svg";
import { ReactComponent as InstantSVG } from "./resource/sparkle.svg";

export interface IPracticeSetTypeSelectionProps {
  canCreateSmartSet: boolean;
  hasNoPremiumAccess: boolean;
  isLoadingCapabilities: boolean;
  handleSelectCustom: () => void;
  handleSelectInstant: () => void;
  handleSelectSmartSet: () => void;
  handleSelectPDLInstantContent: () => void;
}

export const PracticeSetTypeSelection: React.FC<
  IPracticeSetTypeSelectionProps
> = ({
  canCreateSmartSet,
  hasNoPremiumAccess,
  isLoadingCapabilities,
  handleSelectCustom,
  handleSelectInstant,
  handleSelectSmartSet,
  handleSelectPDLInstantContent,
}) => {
  const { isFeatureEnabled } = useAuth();
  const { t } = useTranslation("admin", {
    useSuspense: false,
    keyPrefix: "practiceSetTypeSelection",
  });

  const enablePracticeSetGeneration = isFeatureEnabled(
    "playtime.teacher.enable_practice_set_generation"
  );
  const enablePDLInstantContent = isFeatureEnabled(
    "playtime.teacher.enable_pdl_instant_content"
  );

  return (
    <VStack
      spacing={pxToRem(24)}
      alignItems="flex-start"
      margin={`0 ${pxToRem(60)}`}
    >
      <PracticeSetTypeCard
        heading={t("customHeading")}
        subheading={t("customSubheading")}
        icon={<CustomSVG width={pxToRem(48)} />}
        iconAlignment="center"
        onClick={handleSelectCustom}
      />

      {enablePracticeSetGeneration && !enablePDLInstantContent && (
        <PracticeSetTypeCard
          heading={t("instantSetHeading")}
          subheading={t("instantSetSubheading")}
          isNew
          isPremium
          hasNoPremiumAccess={hasNoPremiumAccess}
          icon={<InstantSVG width={pxToRem(48)} />}
          iconAlignment="center"
          onClick={handleSelectInstant}
        />
      )}

      {enablePracticeSetGeneration && enablePDLInstantContent && (
        <PracticeSetTypeCard
          heading={t("pdlInstantContentHeading")}
          subheading={t("pdlInstantContentSubheading")}
          isNew
          icon={<InstantSVG width={pxToRem(48)} />}
          iconAlignment="center"
          onClick={handleSelectPDLInstantContent}
        />
      )}

      <PracticeSetTypeCard
        heading={t("smartSetHeading")}
        subheading={t("smartSetSubheading")}
        isDisabled={!canCreateSmartSet}
        isLoading={isLoadingCapabilities}
        disabledLabel={t("smartSetDisabledLabel")}
        disabledTooltip={t("smartSetDisabledTooltip")}
        icon={<RemixSVG width={pxToRem(48)} />}
        iconAlignment="center"
        onClick={handleSelectSmartSet}
      />
    </VStack>
  );
};
