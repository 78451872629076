import { SignInIntent } from "@goguardian/types-psi";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { localStoreAuthKeyName } from "links/lib/constants";

import { useAuth } from ".";

export const useSetExternalSignInState = (): void => {
  const { setExternalSignInState } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/external/sign-in") {
      const searchParams = new URLSearchParams(window.location.search);
      localStorage.setItem(
        localStoreAuthKeyName,
        searchParams.get("token") || ""
      );
      setExternalSignInState({
        externalSignInIntent: (searchParams.get("intent") || undefined) as
          | SignInIntent
          | undefined,
        externalSignInRedirectTo: searchParams.get("redirectTo") || undefined,
      });
    }
    // Only run this effect when the pathname changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
