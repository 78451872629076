import { Box } from "@chakra-ui/react";
import { LogLevel, Product } from "@goguardian/types-psi";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useMount } from "react-use";

import { InternationalBlockScreen } from "adminComponents/screens/InternationalBlockScreen";
import "adminComponents/theme/textStyles/fonts.css";
import { AnalyticsProvider } from "lib/contexts/analytics";
import { useAuth, useInitAuth } from "links/lib/features/auth";
import { useSetExternalSignInState } from "links/lib/features/auth/useSetExternalSignInState";
import { UserRole } from "links/lib/types";
import AdminDashboard from "screens/AdminDashboard";
import { AnonymousTeacherProfile } from "screens/AnonymousTeacherProfile";
import AppError from "screens/AppError";
import { Assignment } from "screens/Assignment";
import { ExtendIndividualTrial } from "screens/ExtendIndividualTrial";
import { DeleteAuthUser } from "screens/InternalTools/AutomatedUITesting/DeleteAuthUser";
import { InitiateRecurringNotification } from "screens/InternalTools/AutomatedUITesting/InitiateRecurringNotification";
import { RevealAuthToken } from "screens/InternalTools/AutomatedUITesting/RevealAuthToken";
import AvatarItemManagement from "screens/InternalTools/AvatarItemManagement";
import { LLMProcessFlows } from "screens/InternalTools/LLMProcessFlows";
import Skills from "screens/InternalTools/Skills";
import { UserTrialExtensionManager } from "screens/InternalTools/UserTrialExtensionManager";
import { Proto } from "screens/Proto";
import SignIn from "screens/SignIn";
import SignInStudent from "screens/SignIn/SignInStudent";
import SignInTeacher from "screens/SignIn/SignInTeacher";
import SignInOAuthCallback from "screens/SignInOAuthCallback";
import SignOut from "screens/SignOut";
import { StudentDashboard } from "screens/StudentDashboard";
import { PlaySessionProvider } from "screens/StudentDashboard/contexts/PlaySessionProvider";
import { StudentDashboardDataProvider } from "screens/StudentDashboard/contexts/StudentDashboardDataProvider";
import { StudentNavigationDataProvider } from "screens/StudentDashboard/contexts/StudentNavigationDataProvider";
import { TeacherAgreements } from "screens/TeacherAgreements";
import TeacherDashboard from "screens/TeacherDashboard";
import { GuestTeacherNavigationDataProvider } from "screens/TeacherDashboard/contexts/GuestTeacherNavigationDataProvider";
import { TeacherOnboard } from "screens/TeacherOnboard";
import { TeacherTour } from "screens/TeacherTour";
import { TeacherUnsubscribe } from "screens/TeacherUnsubscribe";
import { UninitializedExplore } from "screens/UninitializedExplore";
import { UninitializedHome } from "screens/UninitializedHome";
import { UserTrialExtension } from "screens/UserTrialExtension";
import { SessionWrapper as Session } from "sessionComponents/screens/Session";
import { StudentModeBanner } from "sharedComponents/atoms/StudentModeBanner";
import { useShowNewLibrary } from "sharedComponents/hooks/useShowNewLibrary";

import { Enroll } from "../Enroll";
import AppAnalytics from "./components/AppAnalytics";
import AppSpinner from "./components/AppSpinner";
import HomeRedirect from "./components/HomeRedirect";
import ProtectedRoute from "./components/ProtectedRoute";

declare global {
  interface Window {
    Appcues: {
      show: (flowId: string) => void;
    };
  }
}

const performRedirect = (
  host: string,
  path?: string,
  search?: string
): JSX.Element => {
  window.location.href = `${window.location.protocol}//${host}${
    path ? path : window.location.pathname
  }${search || ""}`;
  return <></>;
};

const App: React.FC = () => {
  // initialize auth
  const initAuthStatus = useInitAuth();
  const { authUser } = useAuth();
  const newLibraryFeatureEnabled = useShowNewLibrary();
  const location = useLocation();
  useSetExternalSignInState();

  // load translations before showing app content
  const { ready: isTranslationReady, i18n } = useTranslation("translation", {
    useSuspense: false,
  });
  const { ready: isAdminTranslationReady } = useTranslation("admin", {
    useSuspense: false,
  });
  const { ready: isSessionTranslationReady } = useTranslation("session", {
    useSuspense: false,
  });
  const { ready: isMapTranslationReady } = useTranslation("map", {
    useSuspense: false,
  });

  const isLoading =
    initAuthStatus.isLoading ||
    !isTranslationReady ||
    !isAdminTranslationReady ||
    !isSessionTranslationReady ||
    !isMapTranslationReady;

  useMount(() => {
    const psiSetupInterval = setInterval(() => {
      const pear = window.pear;
      if (!pear) return;

      pear.setLogLevel(LogLevel.Warn);
      pear.identity.identifyProduct(Product.GIANT_STEPS);

      clearInterval(psiSetupInterval);
    }, 50);
  });

  // once done loading translation and auth, if auth user present, set UI
  // to auth user language
  useEffect(
    () => {
      if (!isLoading && authUser?.language) {
        i18n.changeLanguage(authUser.language);
        moment.locale(authUser.language);
      }
    },
    // eslint-disable-next-line
    [isLoading, authUser?.language]
  );

  const hostnameBLD = window.location.host.split(".")[0];

  // Replace prefixed `join.` hostname and add prefixed path `/session/join`
  if (hostnameBLD === "join" || hostnameBLD == "stg-join") {
    const path =
      location.pathname.indexOf("/session") === 0
        ? location.pathname
        : `/session/join${location.pathname}`;

    return performRedirect(
      window.location.host.replace("stg-join.", "stg.").replace("join.", ""),
      path,
      window.location.search
    );
  }

  return (
    <AnalyticsProvider>
      <AppAnalytics />
      <StudentModeBanner />
      <Box h="full" as="div" aria-busy={isLoading}>
        {isLoading && <AppSpinner />}
        {!isLoading && (
          <Switch>
            <Route path="/unavailable">
              <InternationalBlockScreen />
            </Route>
            <ProtectedRoute path="/" exact={true}>
              <HomeRedirect />
            </ProtectedRoute>
            <Route path="/profiles/:customUrlCode" exact={true}>
              <AnonymousTeacherProfile />
            </Route>
            <Route path="/enroll/:joinCode" exact={true}>
              <Enroll />
            </Route>
            <Route path="/external/sign-in">
              <HomeRedirect />
            </Route>
            <Route path="/sign-in" exact={true}>
              <SignIn />
            </Route>
            <Route path="/sign-in/student" exact={true}>
              <SignInStudent />
            </Route>
            <Route path="/sign-in/teacher" exact={true}>
              <SignInTeacher />
            </Route>
            <Route path="/sign-in/oauth-callback" exact={true}>
              <SignInOAuthCallback />
            </Route>
            <Route path="/sign-out" exact={true}>
              <SignOut />
            </Route>
            <Route path="/session" exact={false}>
              <Session />
            </Route>
            <ProtectedRoute
              path="/a/onboard"
              role={[UserRole.Admin]}
              exact={false}
            >
              <TeacherOnboard redirect="/a/reporting" />
            </ProtectedRoute>
            <ProtectedRoute
              path="/t/onboard"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
              exact={false}
            >
              <TeacherOnboard />
            </ProtectedRoute>
            <ProtectedRoute
              path="/a/agreements"
              role={[UserRole.Admin]}
              exact={false}
            >
              <TeacherAgreements redirect="/a/reporting" />
            </ProtectedRoute>
            <ProtectedRoute
              path="/t/agreements"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
              exact={false}
            >
              <TeacherAgreements />
            </ProtectedRoute>
            <ProtectedRoute
              path={["/a/tour", "/t/tour"]}
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
              exact={false}
            >
              <TeacherTour />
            </ProtectedRoute>
            <ProtectedRoute path="/s" role={UserRole.Student} exact={false}>
              <StudentDashboard />
            </ProtectedRoute>
            <Route path="/t/account/notifications/unsubscribe">
              <TeacherUnsubscribe />
            </Route>
            <ProtectedRoute
              path="/t"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
              exact={false}
            >
              <TeacherDashboard />
            </ProtectedRoute>
            <Route path="/u/home" exact={true}>
              {newLibraryFeatureEnabled ? (
                <GuestTeacherNavigationDataProvider>
                  <UninitializedExplore />
                </GuestTeacherNavigationDataProvider>
              ) : (
                <UninitializedHome />
              )}
            </Route>
            <ProtectedRoute path="/assignments/:assignmentId">
              <StudentDashboardDataProvider>
                <StudentNavigationDataProvider>
                  <PlaySessionProvider>
                    <Assignment />
                  </PlaySessionProvider>
                </StudentNavigationDataProvider>
              </StudentDashboardDataProvider>
            </ProtectedRoute>
            <ProtectedRoute path="/a" role={[UserRole.Admin]} exact={false}>
              <AdminDashboard />
            </ProtectedRoute>
            <ProtectedRoute
              path="/trials/:guid"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
              renderForPreventedGuest={<Redirect to="/sign-in" />}
              preventGuest={true}
            >
              <UserTrialExtension />
            </ProtectedRoute>
            <ProtectedRoute
              path="/53fb062f-8d3a-42d3-8cb1-f3688c6a5c9d/avatars"
              exact={true}
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
            >
              <AvatarItemManagement />
            </ProtectedRoute>
            <ProtectedRoute
              path="/ce95fe32-e800-42de-80e8-033897d829ae/skills"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
            >
              <Skills />
            </ProtectedRoute>
            <ProtectedRoute
              path="/361acc16-e7f1-4da5-9235-e99f0283baf5/sendnotification"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
            >
              <InitiateRecurringNotification />
            </ProtectedRoute>
            <ProtectedRoute
              path="/e0fe8aed-6b71-420f-8f9f-48780bfbd3dc/llm-process-flows"
              role={[UserRole.ContentSpecialist]}
            >
              <LLMProcessFlows />
            </ProtectedRoute>
            <ProtectedRoute
              path="/ab6c6e1d-a13e-482c-9ed5-83a5a588fa5f/trials"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
              renderForPreventedGuest={<Redirect to="/sign-in" />}
              preventGuest={true}
            >
              <UserTrialExtensionManager />
            </ProtectedRoute>
            <ProtectedRoute
              path="/e2458163-0c22-4f21-aa21-2e6f07829e98/deleteme"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
            >
              <DeleteAuthUser />
            </ProtectedRoute>
            {(window.location.href.startsWith("https://stg") ||
              window.location.href.startsWith("http://localhost")) && (
              <ProtectedRoute
                path="/e2458163-0c22-4f21-aa21-2e6f07829e98/automation-utils"
                role={[
                  UserRole.Admin,
                  UserRole.Teacher,
                  UserRole.ContentSpecialist,
                  UserRole.Student,
                ]}
              >
                <RevealAuthToken />
              </ProtectedRoute>
            )}
            <ProtectedRoute
              path="/9af6dcbe-1b89-4813-85d9-43bd34e93064/trial-extension"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
              renderForPreventedGuest={<Redirect to="/sign-in" />}
              preventGuest={true}
            >
              <ExtendIndividualTrial />
            </ProtectedRoute>
            <ProtectedRoute
              path="/74015edb-6084-43f2-a030-70bfc7eb6b45/trial-extension-90"
              role={[
                UserRole.Admin,
                UserRole.Teacher,
                UserRole.ContentSpecialist,
              ]}
              renderForPreventedGuest={<Redirect to="/sign-in" />}
              preventGuest={true}
            >
              <ExtendIndividualTrial extensionDays={90} />
            </ProtectedRoute>
            {/* Routes for prototyping front-end interactions */}
            <Route path="/proto" exact={false}>
              <Proto />
            </Route>
            <Route path="*">
              <AppError code={404} />
            </Route>
          </Switch>
        )}
      </Box>
    </AnalyticsProvider>
  );
};

export default App;
